import { ThemeProvider } from '@mui/material/styles';
import { Route, Routes } from 'react-router-dom';
import theme from '../assets/js/theme';

import LendingPage from './LendingPage';

function App() {
  return (
    <ThemeProvider theme={theme}>
      {/* <BrowserRouter> */}
      <Routes>
        <Route index path='' element={<LendingPage />} />
      </Routes>
      {/* </BrowserRouter> */}
    </ThemeProvider>
  );
}

export default App;

import teamStyles from '../../../assets/styles/Components/team.module.scss';

const CardTeam = ({ employer }) => (
  <div className={teamStyles.card}>
    <img
      className={teamStyles.card__photo}
      src={employer.photo}
      alt='participant'
    />

    <p className={teamStyles.name}>{employer.name}</p>
    <p className={teamStyles.surname}>{employer.surname}</p>
    <p className={teamStyles.text}>{employer.position}</p>
  </div>
);
export default CardTeam;

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { IconButton } from '@mui/material';
import * as classnames from 'classnames';
import styles from '../../../assets/styles/Components/team.module.scss';

const NextArrow = ({ onClick }) => (
  <IconButton
    aria-label='arrowlefticon'
    onClick={onClick}
    className={classnames(styles.but, styles.but_right)}
  >
    <ArrowRightIcon fontSize='large' />
  </IconButton>
);
export default NextArrow;

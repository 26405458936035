import { Link } from 'react-router-dom';
import { Container, IconButton, Drawer } from '@mui/material';
import { useState } from 'react';
import * as classnames from 'classnames';
import NavBar from './NavBar';
import headerStyles from '../../assets/styles/Components/header.module.scss';
import logo from '../../assets/images/Logo.png';

function Header() {
  const [isMenuOpen, toggleMenu] = useState(false);
  const toggleMenuMode = () => {
    toggleMenu(!isMenuOpen);
  };
  const handleCloseMenu = () => {
    toggleMenu(false);
  };

  return (
    <header className={headerStyles.header}>
      <Container>
        <div
          className={classnames(headerStyles.wrapper, {
            [headerStyles.active_menu]: isMenuOpen,
          })}
        >
          <Link className={headerStyles.logo__wrapper} to='/'>
            <img src={logo} alt='' className={headerStyles.logo__img} />
            <p className={headerStyles.logo__text}>ФАБРИКА БИОКОНСТРУКЦИЙ</p>
          </Link>
          <NavBar isMenuOpen={isMenuOpen} onCloseMenu={handleCloseMenu} />
          <Drawer
            sx={{ zIndex: 'modal' }}
            anchor='top'
            open={isMenuOpen}
            onClose={handleCloseMenu}
          />
          <IconButton
            size='large'
            aria-label='open menu'
            onClick={toggleMenuMode}
            sx={{
              display: { md: 'none', xs: 'flex' },
              position: { xs: 'absolute' },
            }}
            className={classnames(headerStyles.hamburger, {
              [headerStyles.hamburger_active]: isMenuOpen,
            })}
          >
            <span className={headerStyles.hamburger__line} />
            <span className={headerStyles.hamburger__line} />
            <span className={headerStyles.hamburger__line} />
          </IconButton>
        </div>
      </Container>
    </header>
  );
}

export default Header;

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import mainStyles from '../../../assets/styles/Components/lending.module.scss';
import formStyles from '../../../assets/styles/Components/formFeed.module.scss';
import StyledField from './styledField';

const validationSchem = yup.object({
  name: yup
    .string('Укажите Ваше имя')
    .required('Поле обязательно для заполнения'),
  email: yup
    .string('Укажите Ваш email')
    .email('Введите корректный email')
    .required('Поле обязательно для заполнения'),
  message: yup
    .string('Оставьте свое сообщение')
    .required('Поле обязательно для заполнения'),
});

const FormFeedback = () => {
  const [isSend, setIsSend] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      message: '',
    },
    resolver: yupResolver(validationSchem),
  });
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('entry.575437371', data.name);
    formData.append('entry.1447189338', data.email);
    formData.append('entry.986073574', data.message);

    fetch(
      `https://docs.google.com/forms/d/e/1FAIpQLSfR55-VRDRBw6LwAOQFejFDjCgpcrWAvPjGtvEu9KrtxPGROQ/formResponse`,
      {
        // redirect: "follow",
        method: 'POST',
        mode: 'no-cors',
        body: formData,

        header: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then(() => setIsSend(true))
      .catch((err) => console.error(err));
    reset();
  };
  return (
    <>
      <h2 className={mainStyles.h2}>Напишите нам</h2>
      <Grid container spacing={4} justifyContent='flex-start'>
        <Grid item xs={12} md={12}>
          <p className={mainStyles.text}>
            Свяжитесь с нами прямо сейчас, чтобы узнать, как наши продукты могут
            повысить эффективность вашей деятельности в области тканевой
            инженерии. Пожалуйста, заполните и отправьте приведенную ниже форму
            для получения информации и запросов, касающихся тканей и услуг,
            партнерских отношений, отношений с инвесторами или СМИ и прессы.
          </p>
        </Grid>
        <Grid item xs={12} md={6}>
          {!isSend ? (
            <form className={formStyles.form} onSubmit={handleSubmit(onSubmit)}>
              <StyledField
                id='name'
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('name')}
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : ''}
                label='Имя:'
                variant='standard'
              />

              <StyledField
                id='email'
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('email')}
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ''}
                label='Почта:'
                variant='standard'
              />

              <StyledField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('message')}
                error={!!errors.message}
                helperText={errors.message ? errors.message.message : ''}
                id='message'
                label='Сообщение:'
                multiline
                rows={4}
                variant='standard'
              />

              <Button
                type='submit'
                variant='contained'
                style={{ alignSelf: 'flex-start' }}
              >
                Отправить
              </Button>
            </form>
          ) : (
            <p className={mainStyles.text}>Спасибо за ваше сообщение</p>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default FormFeedback;

import { Box, Container, Grid, Stack } from '@mui/material';
import Header from '../components/Lending/Header';
import Footer from '../components/Lending/Footer';
import styles from '../assets/styles/Components/lending.module.scss';
import Product1 from '../assets/images/Pic1.jpg';

import FormFeedback from '../components/Lending/Form/FormFeedback';
import Team from '../components/Lending/Team/Team';
import goal from '../assets/svg/goal.svg';
import Parts from '../components/Lending/Particles/Particlex';

const LendingPage = () => (
  <>
    <div className={styles.parts}>
      <Parts />
    </div>
    <Header />

    <div className={styles.wrapper}>
      <Box
        id='home'
        className={`${styles.container_first} ${styles.container_transparent}`}
      >
        <Container>
          <h1 className={styles.h1}>Инновации для тканей завтрашнего дня</h1>
          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <p className={styles.text}>
                Продукты <strong>Фабрики Биоконструкций</strong> совершают
                революцию в тканевой инженерии с помощью новейшего программного
                обеспечения и устройств.
              </p>
              <p className={styles.text}>
                Основное внимание мы уделяем разработке передового программного
                обеспечения CAD для моделирования скаффолдов, что позволяет
                экономить драгоценное время и создавать исключительные
                биосовместимые решения.
              </p>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box id='about' className={styles.container}>
        <Container>
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            className={styles.first}
            spacing={4}
          >
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <img src={goal} alt='цель' className={styles.goal} />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <h2 className={styles.h2}>Миссия и цели</h2>
              <p className={styles.text}>
                Миссия <strong>Фабрики Биоконструкций</strong> очевидна:
                предоставить исследователям, ученым и медицинским работникам во
                всем мире передовые инструменты, ускоряющие прогресс в области
                тканевой инженерии.
              </p>
              <p className={styles.text}>
                Мы стремимся совершить революцию в создании биосовместимых
                скаффолдов, что в конечном итоге изменит ландшафт регенеративной
                медицины и улучшит жизнь пациентов.
              </p>

              <Stack spacing={3} direction='row'>
                <Stack>
                  <span className={styles.h2_small}> 2021 </span>
                  <span className={`${styles.text} ${styles.text_about}`}>
                    {' '}
                    Дата основания{' '}
                  </span>
                </Stack>

                <Stack>
                  <span className={styles.h2_small}> Сколково </span>
                  <span className={`${styles.text} ${styles.text_about}`}>
                    Резидент{' '}
                  </span>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box
        id='product'
        className={`${styles.container}  ${styles.container_colored}`}
      >
        <Container>
          <h2 className={`${styles.h2} ${styles.h2_products}`}>Продукты</h2>
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            flex-direction='column'
            className={styles.first}
            spacing={8}
          >
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <h3 className={styles.h3}>
                Программное обеспечение (CAD) для моделирования скаффолдов
              </h3>
              <p className={`${styles.text} ${styles.text_product}`}>
                Продукт представляет собой SaaS, разработанный для решения
                проблемы отсутствия программного обеспечения для моделирования
                скаффолдов в России и странах СНГ. Используя это программное
                обеспечение, пользователи могут сэкономить время на
                конструирование биологических скаффолдов и получить больший
                контроль над конечным дизайном скаффолда благодаря возможности
                изменять множество параметров. В конечном итоге, продукт призван
                упростить и оптимизировать процессы моделирования скаффолдов,
                повышая эффективность и адаптацию в области биоконструкций.
              </p>
            </Grid>

            <Grid item xs={12} sm={12} md={5} lg={5}>
              <img src={Product1} className={styles.photo} alt='Product1' />
            </Grid>

            <Grid item xs={12} sm={12} md={7} lg={7}>
              <h3 className={styles.h3}>
                3D-печать скаффолдов для биоконструкций:
              </h3>
              <p className={`${styles.text} ${styles.text_product}`}>
                Наша компания предлагает передовые 3D-печатные скаффолды с
                использованием FDM и SLA технологий. Мы создаем биосовместимые
                пластики и гидрогели для качественных скаффолдов, точно
                соответствующих спецификациям и обладающих сложной внутренней
                структурой. Мы изготовливаем качественные скаффолдов для
                применения в тканевой инженерии согласно спецификациям и вашими
                требованиями
              </p>
            </Grid>

            <Grid item xs={12} sm={12} md={5} lg={5}>
              {/* <img src={Product3} className={styles.photo} alt='Product3' /> */}
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box
        id='special'
        className={`${styles.container} ${styles.container_transparent}`}
      >
        <Container>
          <h2 className={styles.h2}>Что делает нас особенными</h2>

          <p className={styles.text}>
            <strong>Эффективность в лучшем виде: </strong>
            наше новаторское программное обеспечение упрощает создание сложных
            решетчатых конструкций, ускоряя моделирование скаффолдов на
            протяжении всего процесса. Попрощайтесь с трудоемкой ручной работой
            и примите более эффективную стратегию.
          </p>

          <p className={styles.text}>
            <strong>Индивидуальные параметры: </strong>
            теперь скаффолды можно с легкостью создавать под самые разные нужды.
            С помощью нашего программного обеспечения можно вводить
            соответствующие критерии для создания максимально точных и
            индивидуальных скаффолдов.
          </p>

          <p className={styles.text}>
            <strong>Максимальная биосовместимость: </strong>
            мы понимаем, насколько важна биосовместимость в тканевой инженерии.
            Наша программа использует инструмент оптимизации топологии, чтобы
            убедиться, что созданные скаффолды идеально подходят для
            использования по назначению, создавая естественную среду для
            развития тканей.
          </p>
        </Container>
      </Box>

      <Box
        id='team'
        className={`${styles.container} ${styles.container_colored}`}
      >
        <Container>
          <Team />
        </Container>
      </Box>

      <Box className={`${styles.container} `} pb={10}>
        <Container>
          <FormFeedback />
        </Container>
      </Box>
      <Box id='contacts'>
        <Footer />
      </Box>
    </div>
  </>
);

export default LendingPage;

// import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import Linkedin from '@mui/icons-material/LinkedIn';
import { Box, Container } from '@mui/material';
import footerStyles from '../../assets/styles/Components/footer.module.scss';
import sk from '../../assets/images/sk.png';
import skoltech from '../../assets/images/skoltech.png';

function Footer() {
  return (
    <Box pb={2}>
      <Container>
        <div className={footerStyles.wrapper_out}>
          <img src={sk} alt='Сколково' />
          <div className={footerStyles.wrapper}>
            <div className={footerStyles.icons}>
              {/* <div>
                  <IconButton
                    size='large'
                    href='https://www.twitter.com/spheroidrevolution'
                    className={footerStyles.icon}
                  >
                    <TwitterIcon />
                  </IconButton>
                </div>
                <div>
                  <IconButton
                    href='https://www.instagram.com/spheroidrevolution'
                    className={footerStyles.icon}
                  >
                    <InstagramIcon fontSize='inherit' />
                  </IconButton>
                </div> */}

              {/* <IconButton
                href='https://www.linkedin.com/company/viabiosia'
                className={footerStyles.icon}
              >
                <Linkedin fontSize='inherit' />
              </IconButton> */}
            </div>
            <p>bioconstruct@bioconstruct.ru</p>
          </div>
          <img src={skoltech} alt='Skoltech' />
        </div>
      </Container>
    </Box>
  );
}

export default Footer;

import Kat from '../../../assets/images/Katherine.jpg';
import Kirillov from '../../../assets/images/Kirillov.jpg';
import Annikov from '../../../assets/images/Annikov.jpg';
import Vulf from '../../../assets/images/Vulf.jpg';
import Kolomenskiy from '../../../assets/images/dkolomenskiy_avatar.jpg';
import Makarova from '../../../assets/images/Makarova.jpg';

const employers = [
  {
    name: 'Кэтрин',
    surname: 'ВИЛИНСКИ-МАЗУР',
    position: 'Сооснователь, Генеральный директор',
    photo: Kat,
  },
  {
    name: 'Богдан',
    surname: 'КИРИЛЛОВ',
    position: 'Сооснователь, Технический директор',
    photo: Kirillov,
  },

  {
    name: 'Максим',
    surname: 'АННИКОВ',
    position: 'Сооснователь, Директор по маркетингу',
    photo: Annikov,
  },
  {
    name: 'Дмитрий',
    surname: 'КОЛОМЕНСКИЙ',
    position: 'Научный руководитель Проекта',
    photo: Kolomenskiy,
  },
  {
    name: 'Михаил',
    surname: 'ВУЛЬФ',
    position: 'Консультант по Цифровизации и Инженерии (Архитектор решений)',
    photo: Vulf,
  },
  {
    name: 'Полина',
    surname: 'МАКАРОВА',
    position: 'Фронтенд разработчик',
    photo: Makarova,
  },
];

export default employers;

import { makeAutoObservable } from 'mobx';
const arr = require('../mock/results.json');

export default class SpherStore {
  formData = [];
  isLoading = false;
  isLoaded = false;
  isResult = true;
  triangDate = [];

  currElements = [];
  currValues = [];
  currPoints = [];
  minCurrColor;
  maxCurrColor;
  currTimeLength = 1;

  formObj = {
    CR: { caption: 'CR(10-16)', value: '2.47' },
    DT: { caption: 'Timestep(h)', value: '1' },
    T_max: { caption: 'Duration', value: '24' },
    D: { caption: 'Diffusion Coefficient', value: '2' },
    IC: { caption: 'Initial Condition', value: '0.05' },
    V: { caption: 'Volume (spheroid)', value: '0.00514' },
    N: { caption: 'Cells (thd)', value: '15' },
    BC: { caption: 'Boundary Condition', value: '0' },
  };
  stlFile;
  error = null;
  constructor() {
    makeAutoObservable(this);
  }
  clearError() {
    this.error = null;
  }

  addSpher = (form) => {
    const result = Object.keys(form).map((elem) => form[elem].value);
    console.log(result);
    alert(result);
  };

  loadSpher = async (form) => {
    const result = Object.keys(form).map((elem) => form[elem].value);
    let formData = new FormData();
    // todo обработать ошибки
    formData.set('data', result);
    if (this.stlFile) formData.set('file', this.stlFile);
    // запрос на отправку формы

    // const response = await fetch('https://reqbin.com/echo/post/form', {
    //   method: 'POST',
    //   // headers: {
    //   //   Accept: 'application/json',
    //   //   'Content-Type': 'application/json',
    //   // },
    //   body: formData,
    // }).then((response) => {
    //   if (!response.ok) {
    //     alert('Something went wrong, word did not add ...');
    //   } else alert(response.json());
    // });
  };
  setFileStl = (file) => {
    // let formData = new FormData();
    // if (file) formData.set('file', file);
    this.stlFile = file;
  };
  getSpher = (id) => {
    this.triangDate = arr;
    this.currTimeLength = Object.keys(this.triangDate).length - 1;

    this.changeCurr(0);
  };
  changeCurr = (i) => {
    this.currElements = arr[i].elements;
    this.currPoints = arr[i].points;
    this.currColors = arr[i].values;
    this.minCurrColor = Math.min(...this.currColors);
    this.maxCurrColor = Math.max(...this.currColors);
  };
}

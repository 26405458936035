import * as classnames from 'classnames';
import { NavHashLink } from 'react-router-hash-link';
import Scrollspy from 'react-scrollspy';
import headerStyles from '../../assets/styles/Components/header.module.scss';

function NavBar({ isMenuOpen, onCloseMenu }) {
  const handleClick = () => {
    onCloseMenu();
  };
  return (
    <nav
      className={classnames(headerStyles.container, {
        [headerStyles.container_active]: isMenuOpen,
      })}
    >
      <Scrollspy
        items={['home', 'product', 'team', 'contacts']}
        currentClassName={headerStyles.active}
        className={headerStyles.navbar}
      >
        <NavHashLink
          className={headerStyles.link}
          onClick={handleClick}
          smooth
          to='/#home'
        >
          Главная
        </NavHashLink>

        <NavHashLink
          className={headerStyles.link}
          onClick={handleClick}
          smooth
          to='/#product'
        >
          Продукты
        </NavHashLink>
        <NavHashLink
          className={headerStyles.link}
          onClick={handleClick}
          smooth
          to='/#team'
        >
          Команда
        </NavHashLink>
        <NavHashLink
          className={headerStyles.link}
          onClick={handleClick}
          smooth
          to='/#contacts'
        >
          Контакты
        </NavHashLink>
      </Scrollspy>
    </nav>
  );
}

export default NavBar;

import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledField = styled(TextField)({
  '& label': {
    color: '#383232',
    fontFamily: 'Montserrat',
    fontSize: 'large',
    transform: 'translate(0, -10px) scale(1)',
  },
  '& label.Mui-focused': {
    fontSize: 'large',
  },
  '& input': {
    color: '#383232',
  },
  '& textarea': {
    color: '#383232',
  },
  '&:hover label': {
    color: '#000',
  },
  '& .MuiInputBase-root': {
    '& fieldset': {
      borderColor: '#383232',
    },
    '&:before': {
      borderColor: '#383232',
    },
  },
});

export default StyledField;
